// This file is generated by compile-translations.js script
// prettier-ignore
module.exports = [
  {
    "languageCode": "af_ZA",
    "languageName": "Afrikaans",
    "languageNativeName": "Afrikaans",
    "translationRatio": 0.049455640491081754
  },
  {
    "languageCode": "ar_SA",
    "languageName": "Arabic",
    "languageNativeName": "اَلْعَرَبِيَّةُ",
    "translationRatio": 0.9680333564975677
  },
  {
    "languageCode": "az_AZ",
    "languageName": "Azerbaijani",
    "languageNativeName": "azərbaycan dili",
    "translationRatio": 0.039726662033819804
  },
  {
    "languageCode": "bg_BG",
    "languageName": "Bulgarian",
    "languageNativeName": "български език",
    "translationRatio": 0.08663423673847581
  },
  {
    "languageCode": "bn_BD",
    "languageName": "Bengali",
    "languageNativeName": "বাংলা",
    "translationRatio": 0.055478341440815426
  },
  {
    "languageCode": "br_FR",
    "languageName": "Breton",
    "languageNativeName": "brezhoneg",
    "translationRatio": 0.026523048413249928
  },
  {
    "languageCode": "ca_ES",
    "languageName": "Catalan",
    "languageNativeName": "Català",
    "translationRatio": 0.24727820245540888
  },
  {
    "languageCode": "cs_CZ",
    "languageName": "Czech",
    "languageNativeName": "čeština",
    "translationRatio": 0.27866574009728984
  },
  {
    "languageCode": "da_DK",
    "languageName": "Danish",
    "languageNativeName": "Dansk",
    "translationRatio": 0.05895297660412324
  },
  {
    "languageCode": "de_DE",
    "languageName": "German",
    "languageNativeName": "Deutsch",
    "translationRatio": 0.7312948807041928
  },
  {
    "languageCode": "el_GR",
    "languageName": "Greek",
    "languageNativeName": "Ελληνικά",
    "translationRatio": 0.20975214269168407
  },
  {
    "languageCode": "en",
    "languageName": "English",
    "languageNativeName": "English",
    "translationRatio": 0.0696895922093731
  },
  {
    "languageCode": "eo_UY",
    "languageName": "Esperanto",
    "languageNativeName": "Esperanto",
    "translationRatio": 0.21924947880472545
  },
  {
    "languageCode": "es_ES",
    "languageName": "Spanish",
    "languageNativeName": "Español",
    "translationRatio": 0.9669909659485754
  },
  {
    "languageCode": "fa_IR",
    "languageName": "Persian",
    "languageNativeName": "فارسی",
    "translationRatio": 0.2173963400509613
  },
  {
    "languageCode": "fi_FI",
    "languageName": "Finnish",
    "languageNativeName": "suomi",
    "translationRatio": 0.22550382209867958
  },
  {
    "languageCode": "fil_PH",
    "languageName": "Filipino",
    "languageNativeName": "Mga Filipino",
    "translationRatio": 0.27854991892517955
  },
  {
    "languageCode": "fr_FR",
    "languageName": "French",
    "languageNativeName": "Français",
    "translationRatio": 0.9292332638406301
  },
  {
    "languageCode": "ha_HG",
    "languageName": "Hausa",
    "languageNativeName": "هَوُسَ",
    "translationRatio": 0.029997683576557743
  },
  {
    "languageCode": "he_IL",
    "languageName": "Hebrew",
    "languageNativeName": "עברית",
    "translationRatio": 0.18264998841788282
  },
  {
    "languageCode": "hi_IN",
    "languageName": "Hindi",
    "languageNativeName": "हिन्दी",
    "translationRatio": 0.15045170257122997
  },
  {
    "languageCode": "hu_HU",
    "languageName": "Hungarian",
    "languageNativeName": "magyar",
    "translationRatio": 0.36668983090108875
  },
  {
    "languageCode": "id_ID",
    "languageName": "Indonesian",
    "languageNativeName": "Bahasa Indonesia",
    "translationRatio": 0.5077600185313875
  },
  {
    "languageCode": "ig_NG",
    "languageName": "Igbo",
    "languageNativeName": "Asụsụ Igbo",
    "translationRatio": 0.030808431781329637
  },
  {
    "languageCode": "it_IT",
    "languageName": "Italian",
    "languageNativeName": "Italiano",
    "translationRatio": 0.97787815612694
  },
  {
    "languageCode": "ja_JP",
    "languageName": "Japanese",
    "languageNativeName": "日本語",
    "translationRatio": 0.9829742876997916
  },
  {
    "languageCode": "ka_GE",
    "languageName": "Georgian",
    "languageNativeName": "ქართული",
    "translationRatio": 0.05906879777623353
  },
  {
    "languageCode": "km_KH",
    "languageName": "Khmer",
    "languageNativeName": "ខេមរភាសា",
    "translationRatio": 0.0310400741255501
  },
  {
    "languageCode": "ko_KR",
    "languageName": "Korean",
    "languageNativeName": "한국어",
    "translationRatio": 0.8910122770442437
  },
  {
    "languageCode": "lt_LT",
    "languageName": "Lithuanian",
    "languageNativeName": "lietuvių kalba",
    "translationRatio": 0.26036599490386847
  },
  {
    "languageCode": "lv_LV",
    "languageName": "Latvian",
    "languageNativeName": "latviešu valoda",
    "translationRatio": 0.026638869585360214
  },
  {
    "languageCode": "mr_IN",
    "languageName": "Marathi",
    "languageNativeName": "मराठी",
    "translationRatio": 0.03891591382904791
  },
  {
    "languageCode": "ms_MY",
    "languageName": "Malay",
    "languageNativeName": "Bahasa Melayu",
    "translationRatio": 0.05617326847347692
  },
  {
    "languageCode": "my_MM",
    "languageName": "Burmese",
    "languageNativeName": "ဗမာစာ",
    "translationRatio": 0.028723650683344926
  },
  {
    "languageCode": "nl_NL",
    "languageName": "Dutch",
    "languageNativeName": "Nederlands",
    "translationRatio": 0.4819318971507992
  },
  {
    "languageCode": "no_NO",
    "languageName": "Norwegian",
    "languageNativeName": "Norsk",
    "translationRatio": 0.39344452165855914
  },
  {
    "languageCode": "pl_PL",
    "languageName": "Polish",
    "languageNativeName": "Polski",
    "translationRatio": 0.9869122075515404
  },
  {
    "languageCode": "pseudo_LOCALE",
    "languageName": "for development only",
    "languageNativeName": "Pseudolocalization",
    "translationRatio": 0.995739500912964
  },
  {
    "languageCode": "pt_BR",
    "languageName": "Brazilian Portuguese",
    "languageNativeName": "Português brasileiro",
    "translationRatio": 0.8756080611535789
  },
  {
    "languageCode": "pt_PT",
    "languageName": "Portuguese",
    "languageNativeName": "Português",
    "translationRatio": 0.3409775306926106
  },
  {
    "languageCode": "ro_RO",
    "languageName": "Romanian",
    "languageNativeName": "Română",
    "translationRatio": 0.10227009497336115
  },
  {
    "languageCode": "ru_RU",
    "languageName": "Russian",
    "languageNativeName": "Русский",
    "translationRatio": 0.9888811674774148
  },
  {
    "languageCode": "si_LK",
    "languageName": "Sinhala",
    "languageNativeName": "සිංහල",
    "translationRatio": 0.625665971739634
  },
  {
    "languageCode": "sk_SK",
    "languageName": "Slovak",
    "languageNativeName": "slovenčina",
    "translationRatio": 0.16805652073198984
  },
  {
    "languageCode": "sl_SI",
    "languageName": "Slovenian",
    "languageNativeName": "slovenščina",
    "translationRatio": 0.6814917766967802
  },
  {
    "languageCode": "sq_AL",
    "languageName": "Albanian",
    "languageNativeName": "Shqip",
    "translationRatio": 0.15438962242297893
  },
  {
    "languageCode": "sr_CS",
    "languageName": "Serbian (Latin)",
    "languageNativeName": "srpski",
    "translationRatio": 0.13933287004864492
  },
  {
    "languageCode": "sr_SP",
    "languageName": "Serbian",
    "languageNativeName": "српски језик",
    "translationRatio": 0.29163771137363914
  },
  {
    "languageCode": "sv_SE",
    "languageName": "Swedish",
    "languageNativeName": "Svenska",
    "translationRatio": 0.18774611999073432
  },
  {
    "languageCode": "sw_KE",
    "languageName": "Swahili",
    "languageNativeName": "Kiswahili",
    "translationRatio": 0.0267546907574705
  },
  {
    "languageCode": "th_TH",
    "languageName": "Thai",
    "languageNativeName": "ไทย",
    "translationRatio": 0.2844567987028028
  },
  {
    "languageCode": "tr_TR",
    "languageName": "Turkish",
    "languageNativeName": "Türkçe",
    "translationRatio": 0.9762566597173964
  },
  {
    "languageCode": "uk_UA",
    "languageName": "Ukrainian",
    "languageNativeName": "Українська",
    "translationRatio": 0.9740560574473014
  },
  {
    "languageCode": "ur_PK",
    "languageName": "Urdu",
    "languageNativeName": "اردو",
    "translationRatio": 0.03648366921473245
  },
  {
    "languageCode": "uz_UZ",
    "languageName": "Uzbek",
    "languageNativeName": "Ўзбек",
    "translationRatio": 0.03069261060921935
  },
  {
    "languageCode": "vi_VN",
    "languageName": "Vietnamese",
    "languageNativeName": "Tiếng Việt",
    "translationRatio": 0.30229325920778316
  },
  {
    "languageCode": "yo_NG",
    "languageName": "Yoruba",
    "languageNativeName": "Yorùbá",
    "translationRatio": 0.27275886031966645
  },
  {
    "languageCode": "zh_CN",
    "languageName": "Chinese Simplified",
    "languageNativeName": "简化字",
    "translationRatio": 0.9900393791985175
  },
  {
    "languageCode": "zh_TW",
    "languageName": "Chinese Traditional",
    "languageNativeName": "正體字",
    "translationRatio": 0.9415103080843178
  }
];
